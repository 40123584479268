import React from 'react';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';


const TermsAndConditions = () => {
    const navigate = useNavigate();
  return (
    <>
    <Navbar/>
     <div className="aboutUsCont">

        {/* </div>  */}
        {/* <div className="aboutNav dfr">
          <a className="pointer" onClick={()=>navigate("/")}>Home</a>
          <a  onClick={()=>navigate("/about")}>About</a>
          <a  onClick={()=>navigate("/login")}>Login</a>
        </div> */}
     </div>

        <div className="terms-container">
      <h1>Terms and Conditions</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to 5th Poll ("Company", "we", "our", "us"). These Terms and Conditions ("Terms") govern your use of our panel services ("Service") and constitute a legally binding agreement between you ("User", "you", "your") and the Company. By registering for and using our Service, you agree to comply with and be bound by these Terms.
      </p>

      <h2>2. Eligibility</h2>
      <p>
        2.1 You must be at least 18 years old to register for and use our Service.<br />
        2.2 By registering, you represent and warrant that you meet the eligibility requirements and that the information you provide during registration is accurate and complete.<br />
        2.3 If you are under 18 years old, you must have the consent of your parent or legal guardian to use the Service. By registering, you represent and warrant that you meet the eligibility requirements or have obtained the necessary consent.
      </p>

      <h2>3. Registration and Account</h2>
      <p>
        3.1 To use our Service, you must complete the registration process by providing the required information and creating an account ("Account").<br />
        3.2 You are responsible for maintaining the confidentiality of your Account login information and for all activities that occur under your Account.<br />
        3.3 You agree to notify us immediately of any unauthorized use of your Account or any other breach of security.
      </p>

      <h2>4. Use of Service</h2>
      <p>
        4.1 You agree to use the Service only for lawful purposes and in accordance with these Terms.<br />
        4.2 You must not use the Service to:<br />
        - Violate any applicable laws or regulations.<br />
        - Infringe on the intellectual property rights of others.<br />
        - Transmit any harmful, obscene, or otherwise objectionable content.
      </p>

      <h2>5. User Content</h2>
      <p>
        5.1 You retain ownership of the content you submit to the Service ("User Content").<br />
        5.2 By submitting User Content, you grant the Company a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and distribute your User Content in connection with operating the Service.<br />
        5.3 You represent and warrant that you own or have the necessary rights to your User Content and that its use does not violate these Terms.
      </p>

      <h2>6. Privacy and Use of Personally Identifiable Information (PII)</h2>
      <p>
        6.1 Your privacy is important to us. Our Privacy Policy describes how we collect, use, and protect your personal information, including Personally Identifiable Information (PII).<br />
        6.2 By using the Service, you consent to the collection and use of your information, including PII, as outlined in the Privacy Policy. PII may include, but is not limited to, your name, email address, phone number, and payment information.<br />
        6.3 We use PII to:<br />
        - Provide and improve our Service.<br />
        - Process transactions and send related information.<br />
        - Communicate with you, including sending updates, security alerts, and support messages.<br />
        - Personalize your experience and deliver content tailored to your interests.<br />
        - Ensure compliance with legal obligations and protect against fraud.<br />
        6.4 We implement reasonable security measures to protect your PII from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.<br />
        6.5 You have the right to access, correct, or delete your PII as described in our Privacy Policy. For any such requests, please contact us at support(support@5thpoll.com).
      </p>

      <h2>7. Intellectual Property</h2>
      <p>
        7.1 All content and materials available on the Service, including but not limited to text, graphics, logos, and software, are the property of the Company or its licensors and are protected by intellectual property laws.<br />
        7.2 You agree not to reproduce, distribute, modify, or create derivative works from any content or materials without our prior written permission.
      </p>

      <h2>8. Termination</h2>
      <p>
        8.1 We may suspend or terminate your Account and access to the Service at our discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the Service, us, or third parties.<br />
        8.2 You may terminate your Account at any time by contacting us.
      </p>

      <h2>9. Disclaimer of Warranties</h2>
      <p>
        9.1 The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied.<br />
        9.2 We do not warrant that the Service will be uninterrupted, error-free, or free from viruses or other harmful components.
      </p>

      <h2>10. Limitation of Liability</h2>
      <p>
        10.1 To the fullest extent permitted by law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of the Service.
      </p>

      <h2>11. Governing Law</h2>
      <p>
        11.1 These Terms and any disputes related to them will be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of laws principles.
      </p>

      <h2>12. Changes to Terms</h2>
      <p>
        12.1 We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Service. Your continued use of the Service after the posting of any changes constitutes your acceptance of those changes.
      </p>

      <h2>13. Contact Information</h2>
      <p>
        13.1 If you have any questions about these Terms, please contact us at support (support@5thpoll.com).
      </p>

      <p>Team<br />5th Poll<br />May 2024</p>
    </div>
    </>
  
  );
};

export default TermsAndConditions;
