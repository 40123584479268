import React,{useEffect,useState} from "react";
import Router from './components/Router'
import "./css/main.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useIdleTimer } from 'react-idle-timer'
import { ActiveProvider } from './components/ActiveContext';
import renewAccessToken from "./components/renewAccessToken";
import myApi from "./components/MyApi";
import {jwtDecode} from 'jwt-decode';




export function showErrorCard(errMessage,status){
  if(document.querySelector('.errCard')) return;
   let errCard = document.createElement('div');
   errCard.setAttribute('class',`errCard ${status}`);
   errCard.innerText = errMessage;
   document.querySelector('body').appendChild(errCard);
   setTimeout(()=>{
    document.querySelector('.errCard').remove()
  },5000)
}

export async function patchData (url, data) {
  try {
    const token = localStorage.getItem("accessToken");
    const userResponse = await myApi.patch(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return userResponse;
  } catch (err) {
    console.log(err);
    if (localStorage.getItem("refreshToken") !== null) {
      await renewAccessToken();
    }
    return err
  }
};

export async function getData (url) {
  try {
    const token = localStorage.getItem("accessToken");
    const userResponse = await myApi.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return userResponse;
  } catch (err) {
    console.log(err);
    if (localStorage.getItem("refreshToken") !== null) {
      await renewAccessToken();
      window.location.reload();
    }
    return err
  }
};

export async function postData (url, data) {
  try {
    const token = localStorage.getItem("accessToken");
    const userResponse = await myApi.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return userResponse;
  } catch (err) {
    console.log(err);
    if (localStorage.getItem("refreshToken") !== null) {
      await renewAccessToken();
    }
    return err;
  }
};



export default function App() {
  // const [hasCheckedExpiration, setHasCheckedExpiration] = useState(false);
  // const checkTokenExpiration = (token) => {
  //   try {
  //     const decodedToken = jwtDecode(token);
  //     const currentTime = Date.now() / 1000; // get current time in seconds
  
  //     // Calculate time left before token expires
  //     const timeUntilExpiration = decodedToken.exp - currentTime;
  //     console.log(timeUntilExpiration)
  
  //     // Set a threshold for the alert, e.g., 5 minutes (300 seconds) before expiration
  //     const alertThreshold = 5 * 60;
  
  //     if (timeUntilExpiration <= 0) {
  //       // alert('Your session has expired. Please log in again.');
  //     } else if (timeUntilExpiration <= alertThreshold) {
  //       // Set a timeout to show an alert before expiration
  //       setTimeout(() => {
  //         // alert('All set')
  //         renewAccessToken();
  //       }, (timeUntilExpiration) * 1000);
  //     }
  //   } catch (error) {
  //     console.error("Failed to decode token", error);
  //   }
  // };
  // useEffect(() => {
  //   const token = localStorage.getItem('accessToken');
  //   if (token && !hasCheckedExpiration) {
  //     checkTokenExpiration(token);
  //     setHasCheckedExpiration(true);
  //   }
  // }, [hasCheckedExpiration]);

  useEffect(() => {
    AOS.init();
  }, [])

  const [state, setState] = useState('Active')
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0)

  const onIdle = () => {
    setState('Idle')
    // console.log("idle")
    if(localStorage.getItem('accessToken')) localStorage.removeItem('accessToken');
    if(localStorage.getItem('refreshToken')){ localStorage.removeItem('refreshToken');
    window.location.href = (window.location.href).split('/').slice(0, 3).join('/')+"/login";
  }
  }

  const onActive = () => {
    setState('Active')
  }

  const onAction = () => {
    setCount(count + 1)

  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 50*60000,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })



  return (
    <>
         <ActiveProvider>
    <Router/>
         </ActiveProvider>
    </>
  );
}


