import React, { useEffect, useState } from "react";
import dashGirlImg from "../images/dashGirl.png";
import points from "../images/points.png";
import refer from "../images/refer.png";
import giveAway from "../images/giveAway.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DashboardSidebar from "./DashboardComp";
import DashboardTop from "./DashboardTop";
import TrendingSurvey from "./TrendingSurvey";
import "../css/help.css";
import useCurrentUser from "./useCurrentUser";
import { useActiveContext } from "./ActiveContext";
import { getData, postData, showErrorCard } from "../App";

export default function Help() {
  const [currentUser, setCurrentUser] = useCurrentUser();
  const [queryType,setQueryType] = useState();
  const [historyTable,setHistoryTable] = useState([]);
  const [messageInp, setMessageInp] = useState({
    fname: "",
    lname: "",
    email: "",
  
  });
  const {darkTheme} = useActiveContext();

  const darkThemeStyle = darkTheme ? {
    background:'var(--darkBg1)',
    color:'var(--darkText)'
  }:{}

  useEffect(() => {
    if (currentUser)
      setMessageInp({
        fname: currentUser.result.name.split(" ")[0],
        lname: currentUser.result.name.split(" ")[1],
        email: currentUser.result.email,
        mob: currentUser.result.mob,
      });
  }, [currentUser]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update the state based on the input field name
    setMessageInp((prevMessageInp) => ({
      ...prevMessageInp,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e)=>{
    e.preventDefault();
      let data = {
        "queryType":e.target.query.value,
        "name": e.target.fname.value+" "+e.target.lname.value,
        "email": e.target.email.value,
        "countryCode": e.target.code.value,
        "PhoneNo": e.target.mob.value,
        "message": e.target.message.value
    }
   const postResp = await postData('/HelpSupport/Help',data);
  //  console.log(postResp)
  if(postResp.name === 'AxiosError'){
    console.log(postResp.config.data)
    showErrorCard(postResp.response.data.errors,'error');
  }else{
    showErrorCard(postResp.data.success,'success');
    getQueryType()
    e.target.reset() 
  }
  
  }

  const getQueryType = async ()=>{
    const queryresp = await getData("/HelpSupport/Help");
    setQueryType(queryresp.data?.QueryType);
    setHistoryTable(queryresp.data?.Query); 
  }
  useEffect(()=>{
    getQueryType()
  },[])

  // if (currentUser)
    return (
     
            <div className="left help">

              <div className="helpCont thinScrollbar">
              <form className="messageForm" onSubmit={handleFormSubmit}>
                <div className="messageCont">
                  <div className="title">Send us a message</div>
                  <div style={{color:darkTheme&&"var(--darkText)"}}>Tell us your name *</div>
                  <div className="inputCont">
                    <input
                      type="text"
                      placeholder="First Name"
                      name="fname"
                      style={{ marginRight: "1.5%" ,background:darkTheme&&'var(--darkBg1)',color:darkTheme&&'var(--darkText)'}}
                      required
                      value={messageInp.fname}
                      onChange={(e) => handleChange(e)}
                     
                    />
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lname"
                      required
                      value={messageInp.lname}
                      onChange={(e) => handleChange(e)}
                      style={darkThemeStyle}
                    />
                    {/* <input type="text" placeholder="" style={{pointerEvents:"none"}}/> */}
                  </div>
                  {/* <div></div> */}
                  <div style={{color:darkTheme&&"var(--darkText)"}}>ENTER YOUR EMAIL *</div>
                  <div className="inputCont">
                    <input
                      type="email"
                      name="email"
                      placeholder="Eg. example@email.com"
                      required
                      value={messageInp.email}
                      onChange={(e) => handleChange(e)}
                      style={darkThemeStyle}
                    />
                  </div>
                  <div style={{color:darkTheme&&"var(--darkText)"}}>Enter Your Phone Number</div>
                  <div className="inputCont">
                  <input
                   style={{width:"35%",marginRight:"1.5%",background:darkTheme&&'var(--darkBg1)',color:darkTheme&&'var(--darkText)'}}
                      type="number"
                      name="code"
                      placeholder="code e.g. +91 "
                      required
                 
                    />
                    <input
                   
                      type="number"
                      name="mob"
                      placeholder="phone number"
                      required
                      style={darkThemeStyle}
                    
                    />
                     <select name="query" 
                     style={darkThemeStyle}
                      value={messageInp.query}
                      onChange={(e) => handleChange(e)}>
                        <option value="">Select query type</option>
                        {queryType && queryType.map(m=>{
                          return <option value={m.id}>{m.title}</option>
                        })}
                     </select>
                     
                  </div>
                  <div style={{color:darkTheme&&"var(--darkText)"}}>Message *</div>

                  <div className="inputCont">
                    {/* <input type="text" placeholder="Write us a message"/> */}
                    <textarea
                      name=""
                      rows="5"
                      name="message"
                      placeholder="Write your message"
                      style={darkThemeStyle}
                     
                    ></textarea>
                  </div>
                </div>
                <button className="sendMessageBtn">Send Message</button>
              </form>
              {historyTable.length>0 && <div className="helpHistory">
                        <div className="title">Support History</div>
                        <table>
  <thead>
    <tr>
      <th>Token ID</th>
      <th>Query Type</th>
      <th>Query Status</th>
      <th>Query Date</th>
    </tr>
  </thead>
  <tbody>
    {historyTable?.map((m) => (
      <tr key={m.tokenId}>
        <td>{m.tokenId}</td>
        <td>{m.queryType}</td>
        <td>{m.queryStatus}</td>
        <td>{m.Date}</td>
      </tr>
    ))}
  </tbody>
</table>

              </div>}
              </div>
      
              
            </div>

          
    );
}


