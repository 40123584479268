// utils.js
const getLanguage = async () => {
  return 'en'; // Simulating an asynchronous operation
}

const getCountry = async () => {
  try {
      // const response = await fetch('https://ipapi.co/json/');
      // const data = await response.json();
      // return data.country_name;
      return 'India'
  } catch (error) {
      console.error('Error fetching the country name:', error);
      return null;
  }
}

exports.currLang = getLanguage;
exports.currCountry = getCountry;
