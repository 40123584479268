import React from "react";
import panRibbon from "../images/panRibbon.png";
import panLogo from "../images/panelLogo.png";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

function AboutUs() {
  const navigate = useNavigate();
  return (
    <>
      <div className="aboutUsCont" style={{paddingTop:'17vh'}}>
        <Navbar active='about'/>
        {/* <div className="panLogoDiv"> */}
        {/* <img src={panLogo} alt="" onClick={()=>navigate("/")} />
        <div style={{color:'var(--iconCol)',textWrap:'nowrap',marginLeft:'4%',marginTop:'-6%'}}>Every opinion matter</div> */}
        {/* </div>  */}
        {/* <div className="aboutNav dfr">
          <a className="pointer" onClick={()=>navigate("/")}>Home</a>
          <a  onClick={()=>navigate("/about")}>About</a>
          <a  onClick={()=>navigate("/login")}>Login</a>
        </div> */}
          <div className="aboutContainer">

          
        <div className="dfc aboutUsTag">
          <div>about us</div>
          <div className="dfc">5th Poll</div>
        </div>
        <div className="dfr welTag" data-aos='zoom-in'>
          Welcome to 5th Poll - Where Your Opinions Earn You Rewards!
        </div>
        <div className="par1">
          At 5th Poll, we're not just a poll company; we're a vibrant community
          that values your opinions and believes in rewarding you for sharing
          them. Join us on an exciting journey where your insights have the
          power to influence brands, products, and services, all while earning
          fantastic rewards along the way.
        </div>
        <div className="flex dfr workJoin">
          <div className="work dfc">
            <div className="bold dfc how">How It Works:</div>
            <div className="workCont">
              <div>
                {" "}
                <div data-aos="fade-right" data-aos-delay='700' data-aos-duration="1000" data-aos-offset="10"> <span className="bold">1. Join the Community:</span> Signing up
                with 5th Poll is your first step toward becoming a valued member
                of our community. Whether you're a student, a professional, a
                homemaker, or anyone with opinions to share, you're welcome
                here. </div>
                <div data-aos="fade-right" data-aos-delay='1500' data-aos-duration="1000" data-aos-offset="10"> <span className="bold">2. Participate in Polls:</span> We offer
                a wide range of polls on topics that matter to you - from
                consumer preferences to social issues. Share your thoughts
                through our user-friendly platform and contribute to shaping
                products and services.</div>
                <div data-aos="fade-right" data-aos-delay='2000' data-aos-duration="1000" data-aos-offset="10"> <span className="bold">3. Earn Rewards:</span> Your time is
                valuable, and we recognize that. For every poll you participate
                in, you'll earn points that can be redeemed for exciting
                rewards, including gift cards, vouchers, merchandise, and more.</div>
                <div data-aos="fade-right" data-aos-delay='2500' data-aos-duration="1000" data-aos-offset="10"> <span className="bold">4. Engage and Interact:</span> Connect
                with fellow members who share your interests. Discuss poll
                results, exchange viewpoints, and be part of a dynamic community
                that thrives on meaningful conversations.</div>
              
               
              </div>
            </div>
          </div>

          <div className="join dfc">
            <img src={panRibbon} alt="" />
            <div className="bold dfc joinTag">Join 5th Poll Today:</div>
            <div>
              Embrace the opportunity to influence the world around you while
              earning rewards that make a difference in your life. Join 5th Poll
              today and be part of a dynamic community where sharing your
              insights translates into tangible benefits. Your opinions matter -
              let them be heard, and let your voice earn you exciting rewards!
            </div>
          </div>
        </div>
      </div>

      <div className="aboutUsCont">
        {/* <div className='aboutNav dfr'>
        <a href='/'>HOME</a>
        <a href='/about'>ABOUT</a>
        <a href='/services'>SERVICES</a>
        <a href='/contact'>CONTACT</a>
    </div> */}

        <div className="dfc aboutUsTag">
          <div>WHY WE</div>
          <div className="dfc">5th Poll</div>
        </div>
        <div className="dfr welTag">
          Welcome to 5th Poll - Where Your Opinions Earn You Rewards!
        </div>
        <div className="choose">
          <div className="dfr bold">Why Choose 5th Poll:</div>
          <div>
            1. <b>Rewards</b> That Matter: We're not just about numbers; we're
            about rewarding you with items that enhance your lifestyle. Enjoy
            the satisfaction of sharing your opinions while treating yourself to
            your favorite rewards.
          </div>
          <div>
            2. <b>Diverse Polls:</b> Our polls cover a diverse range of topics,
            ensuring that there's something for everyone. Your unique insights
            help brands refine their offerings and cater to a variety of
            preferences.
          </div>
          <div>
            3. <b>User-Centric Experience:</b> Our platform is designed to be
            user-friendly and accessible. No matter your tech-savviness, you'll
            find it easy to navigate, participate, and engage.
          </div>
          <div>
            4. <b>Community Spirit:</b> When you join 5th Poll, you're not just
            earning rewards; you're becoming part of a community that values
            your voice. Share, learn, and grow with others who care about making
            an impact.
          </div>
        </div>

        <div className="vision dfr">
            <div className="dfr leftQuote"><i className="fa-solid fa-quote-left fa-2xl" style={{color:'#000000'}}></i></div>
            <div className="dfr midQuote" data-aos='fade-in' data-aos-delay="250" data-aos-duration="1000">
            <div>Our vision at 5th Poll is to bridge the gap between individuals like <span>YOU</span> and the
<span > BRANDS</span> that value your feedback. We believe that your opinions are valuable and
deserve to be heard, and what better way to show our appreciation than by
offering you enticing rewards for your participation?</div>
            </div>
           
            <div className="dfr rightQuote"><i className="fa-solid fa-quote-right fa-2xl" style={{color:'#000000'}}></i></div>
        </div>
        <img className="ribbonBack" src={panRibbon} alt="" />
      </div>
      </div>
    </>
  );
}

export default AboutUs;
