import React,{useEffect, useState} from 'react'
import dashGirlImg from "../images/dashGirl.png";
import points from "../images/points.png";
import refer from "../images/refer.png";
import giveAway from "../images/giveAway.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useCurrentUser from "./useCurrentUser";
import { Navigate, useNavigate } from "react-router-dom";
import { useActiveContext } from './ActiveContext';
import SurveyCard from './SurveyCard';
import Overlay from './Overlay';

export default function DashboardHome() {
    const [profileComp, setProfileComp] = useState(0);
    const navigate = useNavigate();
    const { active,setActive ,darkTheme} = useActiveContext();
    const [currentUser,setCurrentUser] = useCurrentUser();

    useEffect(()=>{
      if(currentUser){
        setProfileComp(currentUser.result.profilePercent)
      }
    },[currentUser])
  return (
    <div className="left" >
    <div className="card flex" data-aos="fade-down" style={{background:"var(--iconCol)"}}>
      <div className="text">
        <div>
          Your profile tells your story - make sure it' s current and
          compelling.
        </div>
        
        <div>
          <a onClick={()=>{setActive("profile"); navigate('/profile')}}>
            <button>Update</button>
          </a>

          {/* <button>Learn more</button> */}
        </div>
        
      </div>
      <div className="imgDiv">
        <img src={dashGirlImg} alt="" />
      </div>
    </div>
    {/* <div className="buttonCont">
      <button>About</button>
      <button>Hot</button>
      <button>Trend</button>
      <button>Shuffle</button>
      <button>Following</button>
    </div> */}

    <div className={darkTheme?'miniCardCont darkCard':'miniCardCont'}>
      <div className="miniCard pointer" onClick={()=>{setActive("profile"); navigate('/profile')}} style={{alignItems:'center'}} data-aos="fade-right">
        <div style={{cursor:'pointer',alignItems:'center'}} className="imgDiv progDiv" onClick={()=>setActive("profile")}>
        
         
          <CircularProgressbar
            value={profileComp}
            text={`${profileComp}%`}
          />
        
          
        </div>
        <div>
          <div className="bold">Your Profile</div>
          <div className="greyCol">
            Complete to <br /> earn more
          </div>
          <a onClick={()=>{setActive("profile"); navigate('/profile')}} className="seeDetailsBtn">
          
            &gt; See Details
          </a>
        </div>
      </div>
      <div className="miniCard pointer" onClick={()=>{setActive("redeem"); navigate("/redeem")}} data-aos="fade-left">
        <div className="imgDiv">
          <img src={points} alt="" />
        </div>
        <div>
          <div className="bold">Total Points</div>
          <div className="greyCol">
            Click to <br /> Redeem
          </div>
          <a onClick={()=>{setActive("redeem"); navigate("/redeem")}} className="seeDetailsBtn">
           
            &gt; See Details
          </a>
        </div>
      </div>
      <div className="miniCard pointer" onClick={()=>{setActive("giveaway"); navigate("/giveaway")}} data-aos="fade-right">
        <div className="imgDiv">
          <img src={giveAway} alt="" />
        </div>
        <div>
          <div className="bold">Give away</div>
          <div className="greyCol">Participate</div>
          <a onClick={()=>{setActive("giveaway"); navigate("/giveaway")}} className="seeDetailsBtn">
          
            &gt; See Details
          </a>
        </div>
      </div>
      <div className="miniCard pointer" onClick={()=>{setActive("refer"); navigate('/refer')}} data-aos="fade-left">
        <div className="imgDiv">
          <img src={refer} alt="" />
        </div>
        <div>
          <div className="bold">Refer to Earn</div>
          <div className="greyCol">Join</div>
          <a onClick={()=>{setActive("refer"); navigate('/refer')}} className="seeDetailsBtn">
          
            &gt; See Details 
          </a>
        </div>
      </div>
    </div>
   
  </div>
  )
}
