import { useEffect, useState } from 'react';
import myApi from './MyApi';
import renewAccessToken from './renewAccessToken';


function useCurrentUser() {
    const [currentUser, setCurrentUser] = useState(null);
    // console.log(currentUser)
  useEffect(() => {
    // Get the JWT token from local storage
    const token = localStorage.getItem('accessToken');
    // console.log(token)
    // Make an authenticated API request
    myApi
      .get('/profile/userProfile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
      //  response.data.result.pic = "123"
       let userData = response.data
       console.log(userData)
      //  console.log(response.data)
       setCurrentUser(userData)

      //  myApi
      // .get('/panel/ProfileIndia/profileImg', {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // })
      // .then((picResp) => {
      //   // console.log(picResp.data.profileImage);
      //   const originalURL = myApi.defaults.baseURL;
      //   const modifiedURL = originalURL.replace('/FifthPoll/api', '');
      //   const picUrl = modifiedURL+picResp.data.profileImage;
      //   response.data.result.picUrl = picUrl
      //   setCurrentUser(response.data)
      // })
      })
      .catch((error) => {
        console.log(error)
        if(localStorage.getItem("refreshToken") != null){
          renewAccessToken();
        }
      });
  }, []);

  return [currentUser,setCurrentUser];
}

export default useCurrentUser;
