import React,{useEffect,useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import panFlower from '../images/panFlower.png'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "../css/giveaway.css";
import { getData, patchData} from "../App";
import { useActiveContext } from "./ActiveContext";


export default function Giveaway() {
    // const [giveAwayList,setGiveAwayList] = useState([]);
    const [giveAwayList,setGiveAwayList] = useState([{}]);
    const [isLoading,setIsLoading] = useState(false);
    const {darkTheme} = useActiveContext();
   const handleCardClick = async (id,link,isStart)=>{
    if(!isStart){
        setIsLoading(true);  
        const isStartResp = await patchData(`/redeem/GiveAway`,{is_Start:true,id:id});
        setIsLoading(false);
    }
    window.location.href = link;
      

   }



   const getGiveAwayList = async ()=>{
    const giveAwayListResp = await getData('/redeem/GiveAway');
    setGiveAwayList(giveAwayListResp.data.result);
    // console.log(giveAwayListResp.data)
   }
    useEffect(()=>{
        getGiveAwayList()
    },[])
    
    const changeStatus = async (id)=>{
        const newStatus = await patchData(`/redeem/GiveAway`,{is_read:true,id:id})
    }
    const swiperSlideChange = (e)=>{
        let slideIdx = e.activeIndex;
        let currElem = giveAwayList[slideIdx]
        // console.log(currElem)
        if(currElem && !currElem.is_read){
            // console.log("yes")
            changeStatus(currElem.id)
        }

    }
    return (

          <div className="giveaway left">
              {giveAwayList.length>0 &&<Swiper
modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
spaceBetween={50}
slidesPerView={1}
navigation
loop={true}
autoplay={{
    delay: 2500,
    disableOnInteraction: false
}}
pagination={{ clickable: true }}
// scrollbar={{ draggable: true }}
onSlideChange={swiperSlideChange}


>

  {giveAwayList && giveAwayList.map((m)=> 
        //    <SwiperSlide> <GiveawayCard img={m.BackgroundImage} link={m.link}/></SwiperSlide>
           <SwiperSlide>
              <a className="giveAwayCard shoes flex pointer"  onClick={()=>handleCardClick(m.id,m.link,m.is_Start)}>
    <img src={m.BackgroundImage} alt="" />
    </a>
           </SwiperSlide>
            )}


</Swiper>}
{giveAwayList.length===0&&<div className='dfc' style={{height:'90%',fontSize:'1.5em',color:darkTheme&&'var(--darkText)'}}>There are no giveaway for you</div>}
{isLoading && <div className='loader' style={{position:"absolute",top:0,left:0,background:'rgba(0, 0, 0, 0.24)',backdropFilter:"blur(5px)",zIndex:1}}>
        <img src={panFlower} alt="" />
    </div>}
          </div>
  );
}
