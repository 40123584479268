import React from 'react'
import panLogo from "../images/panelLogo.png";
import { useNavigate,Link } from 'react-router-dom';

export default function Navbar({active}) {
    const navigate = useNavigate();
   
  return (
    <nav className='navbar'>
    <div className='logoComp'>
        <img  src={panLogo} alt="" onClick={()=>navigate("/")} />
        <div className='logoText' style={{color:'var(--iconCol)',textWrap:'nowrap'}}>Every opinion matters</div>
    </div>

      <div className="navBtnCont">
        <div className='navBtn' style={active==='home' ? {background:'var(--iconCol)',color:'white'}:{}} onClick={()=>navigate('/')}>Home</div>
        <div className='navBtn' style={active==='about' ? {background:'var(--iconCol)',color:'white'}:{}} onClick={()=>navigate('/about')}>About</div>
        <div className='navBtn'style={active==='login' ? {background:'var(--iconCol)',color:'white'}:{}}  onClick={()=>navigate('/login')}>Login</div>
      </div>

    </nav>


  )
}
