import React, { useState } from "react";
import { useActiveContext } from "./ActiveContext";
import searchListArr from "./searchList";

function SearchBar() {
  const [searchListDis, setSearchListDis] = useState(false);
  const { active, setActive, sideBarDis, setSideBarDis,darkTheme } = useActiveContext();
  const [searchItems, setSearchItems] = useState([]);
  const [searchList, setSearchList] = useState(searchListArr);
  const [currListItem, setCurrListItem] = useState(-1);
  const handleBlur = (e) => {
    setCurrListItem(-1)
    setTimeout(() => {
      setSearchListDis(false);
    }, 100);
  };
  const makeUnique = (arr) => {
    // Create a Set from the array to eliminate duplicates
    const uniqueSet = new Set(arr);

    // Convert the Set back to an array
    const uniqueArr = Array.from(uniqueSet);

    return uniqueArr;
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (currListItem === searchItems.length-1) {
        setCurrListItem(0);
      }
      else{
        setCurrListItem((prev) => {
          return prev + 1;
        });
      }
     
    }
    if (e.key === "ArrowUp") {
      if (currListItem === 0) {
        setCurrListItem(searchItems.length-1);
      }else{
        setCurrListItem((prev) => {
          return prev - 1;
        });
      }
    
    }
    if(e.key === "Enter"){
      let searchList = document.querySelectorAll(".searchList")[currListItem];
      if(searchList){
        // console.log(searchList.click())
      }
    }

   


  };
  const handleSearch = (searchText) => {
    let filterArr = [];
    for (let i = 0; i < searchList.length; i++) {
      for (let j = 0; j < searchList[i].keywords.length; j++) {
        if (compareWords(searchList[i].keywords[j], searchText)) {
          filterArr.push({
            searchKey: searchList[i].searchKey,
            path: searchList[i].path,
          });
        }
      }
    }
    const dataArray = Object.values(filterArr);

    const uniqueArray = dataArray.reduce((acc, current) => {
      const isUnique = !acc.find(
        (item) =>
          item.searchKey === current.searchKey && item.path === current.path
      );
      if (isUnique) {
        acc.push(current);
      }
      return acc;
    }, []);
    setSearchItems(uniqueArray);
  };

  const compareWords = (word1, word2) => {
    // Convert both words to lowercase
    word1 = word1.toLowerCase();
    word2 = word2.toLowerCase();

    // Calculate the length of the longer word
    const maxLength = Math.max(word1.length, word2.length);

    // Initialize a counter for matching characters
    let matchCount = 0;

    // Iterate over each character in the first word
    for (let i = 0; i < word1.length; i++) {
      // Check if the character exists in the second word
      if (word2.includes(word1[i])) {
        // Increment the match count
        matchCount++;
      }
    }

    // Calculate the similarity percentage
    const similarityPercentage = (matchCount / maxLength) * 100;

    // Return true if similarity is above 60%, otherwise return false
    return similarityPercentage >= 70;
  };

  return (
    <>
      <div className="searchCont" style={{background:darkTheme&&'var(--darkBg)'}}>
        <i className="fa-solid fa-search" style={{color:darkTheme&&'var(--darkText)'}}></i>
        <input
          type="text"
          placeholder="Search"
          onFocus={() => setSearchListDis(true)}
          onBlur={(e) => handleBlur(e)}
          onInput={(e) => handleSearch(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          style={{background:darkTheme&&'var(--darkBg)',color:darkTheme&&'var(--darkText)'}}
        />
      </div>
      {searchItems.length >= 1 && (
        <div
          className="searchListCont"
          style={{ display: searchListDis ? "flex" : "none" ,background:darkTheme&&'var(--darkBg)',color:darkTheme&&'var(--darkText)'}}
        >
          {searchItems.map((m, i) => {
            return (
              <a
                href={m.path}
                className="searchList"
                style={{
                  background: i === currListItem ? "var(--iconCol)" : "",
                  color: i === currListItem || darkTheme ? "white" : "",

                }}
              >
                {m.searchKey}
              </a>
            );
          })}
        </div>
      )}
    </>
  );
}

export default SearchBar;
