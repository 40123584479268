// ActiveContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import DashboardTop from './DashboardTop';
import DashboardHome from './DashboardHome';
import Message from './Message';
import Redeem from './Redeem';
import MyAccount from './MyAccount';
import Settings from './Settings';
import Help from './Help';
import TrendingSurvey from './TrendingSurvey';
import Giveaway from './Giveaway';
import Refer from './Refer';
import useIsMobile from './useIsMobile';

const ActiveContext = createContext();

export const useActiveContext = () => {
  return useContext(ActiveContext);
};

export const ActiveProvider = ({ children }) => {
  const isMobile = useIsMobile()
  const [active,setActive] = useState("dashboard");
  // const [active,setActive] = useState("redeem");
  const [sideBarDis,setSideBarDis] = useState(!isMobile);
  const [darkTheme,setDarkTheme] = useState(true);
  useEffect(()=>{
      let theme = localStorage.getItem('theme');
      // console.log(theme)
      if(theme && theme=='dark'){
          setDarkTheme(true)
        }else if(theme && theme=='light'){
        setDarkTheme(false)
      }
  },[])

  return (
    <ActiveContext.Provider value={{ active, setActive ,sideBarDis,setSideBarDis,darkTheme,setDarkTheme}}>
    {children}
    </ActiveContext.Provider>
  );
};
